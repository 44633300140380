var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-interface"},[(_vm.isLoading)?_c('Loading',{attrs:{"version":"v3"}}):[_c('div',[_c('div',{staticClass:"headline-small"},[_vm._v(" "+_vm._s(_vm.$tc((_vm.typeStringLabel + ".title_funds")))+" ")]),_c('div',{staticClass:"flex justify-center mt-s40 mb-s12"},[_c('div',{staticClass:"input-amount",style:({ opacity: _vm.amount > 0 ? 1 : 0.3 })},[_vm._v(" $ ")]),_c('money',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '392px',
            minWidth: 'none',
            comfortZone: 1,
          }),expression:"{\n            maxWidth: '392px',\n            minWidth: 'none',\n            comfortZone: 1,\n          }"}],ref:"amount",staticClass:"w-full input-amount no-arrows",style:({ opacity: _vm.amount > 0 ? 1 : 0.3 }),attrs:{"data-testid":"input-money","data-cy":"input-money","placeholder":0},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),[(_vm.amount > _vm.limit && _vm.isDeposit)?_c('div',{staticClass:"\n            border\n            bg-background-negative-text\n            body-text-large\n            text-text-negative\n            border-border-negative\n            rounded-16\n            px-s16\n            py-s12\n          "},[_vm._v(" "+_vm._s(_vm.$t('wallet.amount_over_daily', { limit: _vm.numberFormat(_vm.limit, 2, false, true), type: _vm.typeStringLabel, }))+" "),_c('div',{staticClass:"underline capitalize cursor-pointer",on:{"click":function($event){return _vm.showModal('WalletTransferLimit', {
                type: _vm.typeStringLabel,
                isClosable: false,
                noPadding: true,
              })}}},[_vm._v(" "+_vm._s(_vm.$t('wallet.view_limits', { type: _vm.typeStringLabel }))+" ")])]):_c('div',{staticClass:"estimated-time subheadline-x-small"},[_c('span',[_vm._v(_vm._s(_vm.$t('wallet.estimated_time'))+": "+_vm._s(_vm.$t(("wallet.estimated_time_options." + _vm.estimatedTimeOption))))])])],_c('div',{staticClass:"text-center mt-s12"},[(!_vm.linkedMethods)?_c('SkeletonLoading',{attrs:{"height":"58px","border-radius":"20px"}}):_c('PaymentMethodSelectorDropdown',{attrs:{"data-testid":"select-payment-method","data-cy":"select-payment-method","methods-data":_vm.linkedMethods,"is-deposit":_vm.isDeposit,"limits":_vm.limits,"initial-selected-method":_vm.selectedMethodData},on:{"addNewMethod":_vm.addNewMethod,"toggleDropdown":_vm.toggleDropdown,"removeMethod":_vm.removeMethod,"selectMethod":_vm.selectMethod}})],1),_c('div',{staticClass:"subheadline-x-small mt-s32"},[_c('div',{staticClass:"flex justify-between"},[_c('span',[_vm._v(_vm._s(_vm.$t((_vm.typeStringLabel + ".minimum"))))]),_c('span',[_vm._v(_vm._s(_vm.numberFormat(_vm.minimumAmount, 2, false, true)))])]),_c('div',{staticClass:"flex justify-between mt-s16"},[_c('span',[_vm._v(_vm._s(_vm.$t('wallet.your_balance')))]),_c('span',[_vm._v(_vm._s(_vm.numberFormat(_vm.userBalance, 2, false, true)))])]),_c('div',{staticClass:"flex justify-between mt-s16"},[_c('span',[_vm._v(_vm._s(_vm.$t((_vm.typeStringLabel + ".fee"))))]),_c('span',[_vm._v(_vm._s(_vm.numberFormat(_vm.fee, 2, false, true)))])]),_c('div',{staticClass:"flex justify-between mt-s16"},[_c('span',[_vm._v(_vm._s(_vm.$t((_vm.typeStringLabel + ".you_will_receive"))))]),_c('span',[_vm._v(_vm._s(_vm.numberFormat(_vm.userWillReceive, 2, false, true)))])])])],2),_c('div',[_c('ButtonV2',{attrs:{"wide":"","size":"medium","data-testid":"button-confirm-deposit","data-cy":"button-confirm-deposit","label":_vm.$tc((_vm.typeStringLabel + ".confirm")),"inactive":!_vm.amountIsValid || _vm.transactionLoading},on:{"onClick":_vm.handleTransaction}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }