<template>
  <div @click="toggleDropdown()" class="relative">
    <!-- Closed area -->
    <div class="dropdown-container">
      <div class="flex items-center">
        <div class="method-icon-container">
          <font-awesome-icon
            role="icon"
            :icon="[
              getIcon(selectedMethod).style,
              getIcon(selectedMethod).name,
            ]"
            class="method-icon"
          />
        </div>
        <div class="text-left ml-s16">
          <div class="body-text-x-large">
            {{ getDetails(selectedMethod) }}
          </div>
          <div class="body-text-x-medium mt-s4 text-text-inactive">
            <template v-if="isDeposit">
              {{
                $t(`wallet.daily_${isDeposit ? "deposit" : "withdraw"}_limit`)
              }}:
              <span class="text-text-positive">
                {{ numberFormat(limit, 2, false, true) }}
              </span>
            </template>
            <span v-else>
              {{ getTypeString(selectedMethod) }}
            </span>
          </div>
        </div>
      </div>

      <div>
        <font-awesome-icon
          role="icon"
          icon="chevron-down"
          class="mx-s12 text-text-active-2 default-transition"
          :class="{ 'rotate-180': isOpen }"
        />
      </div>
    </div>

    <!-- Open options area -->
    <div :class="{ hidden: !isOpen }" class="dropdown-options-container">
      <div class="empty-space"></div>

      <div class="dropdown-options scrollable-modal-element">
        <div
          @click="selectOption(index)"
          v-for="(n, index) in methodsData.length"
          v-bind:key="`payment_method_${index}`"
          class="dropdown-option hover:opacity-80"
        >
          <div class="flex items-center">
            <div class="method-icon-container">
              <font-awesome-icon
                role="icon"
                :icon="[
                  getIcon(methodsData[index]).style,
                  getIcon(methodsData[index]).name,
                ]"
                class="method-icon"
              />
            </div>
            <div class="text-left ml-s16">
              <div class="body-text-x-large">
                {{ getDetails(methodsData[index]) }}
              </div>
              <div class="body-text-x-medium text-text-inactive mt-s4">
                {{ getTypeString(methodsData[index]) }}
              </div>
            </div>
          </div>

          <div
            class="px-s4 text-text-inactive"
            @click="handleRemove(methodsData[index])"
            v-if="methodsData[index].type !== CirclePaymentType.BLOCKCHAIN"
          >
            <IconTrashBin role="icon" class="w-s24 h-s24 hover:opacity-70" />
          </div>
        </div>
      </div>

      <div class="bg-opacity-50 p-s8 bg-background-active">
        <ButtonV2
          @onClick="$emit('addNewMethod')"
          testId="btn-add"
          :label="$tc('payment_methods.add')"
          size="medium"
          wide
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import CirclePaymentType from '@/enums/CirclePaymentType';
import IconTrashBin from '@/assets/icons/trash-bin.svg';

export default {
  name: 'PaymentMethodSelectorDropdown',
  components: {
    ButtonV2,
    IconTrashBin,
  },

  computed: {
    limit() {
      return this.lodashGet(
        this.limits.find((method) => method.type === this.methodType),
        'remaining.daily',
        0
      );
    },

    methodType() {
      return this.lodashGet(this.selectedMethod, 'type', null);
    },

    selectedMethod() {
      return this.methodsData[this.selectedMethodIndex] || {};
    },
  },

  data() {
    return {
      CirclePaymentType,
      isOpen: false,
      selectedMethodIndex: 0,
    };
  },

  mounted() {
    const methodIndex = this.methodsData.findIndex(
      (method) => method.id === this.initialSelectedMethod.id
    );

    this.selectedMethodIndex = methodIndex !== -1 ? methodIndex : 0;
  },

  methods: {
    getDetails(method) {
      switch (method.type) {
        case CirclePaymentType.CARD:
          return `${method.network} **** ${method.last4}`;
        default:
          return method.nickname || method.description;
      }
    },

    getIcon(method) {
      switch (method.type) {
        case CirclePaymentType.CARD: {
          switch (method.network) {
            case 'VISA':
              return { name: 'cc-visa', style: 'fab' };
            case 'MASTERCARD':
              return { name: 'cc-mastercard', style: 'fab' };
            default:
              return { name: 'credit-card', style: 'fas' };
          }
        }
        case CirclePaymentType.BLOCKCHAIN:
          return { name: 'dollar-sign', style: 'fas' };
        case CirclePaymentType.WIRE:
          return { name: 'exchange-alt', style: 'fas' };
        default:
          return { name: 'university', style: 'fas' };
      }
    },

    getTypeString(method) {
      switch (method.type) {
        case CirclePaymentType.CARD:
          return this.$t('wallet.credit_debit_card');
        case CirclePaymentType.BLOCKCHAIN:
          return this.$t('wallet.blockchain_description');
        case CirclePaymentType.WIRE:
          return this.$t('wallet.wire_transfer');
        default:
          return this.$t('wallet.bank_account');
      }
    },

    selectOption(index) {
      this.selectedMethodIndex = index;
      this.$emit('selectMethod', this.methodsData[index]);
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$emit('toggleDropdown', this.isOpen);
    },

    handleRemove(method) {
      this.$emit('removeMethod', method);
    },
  },

  props: {
    isDeposit: {
      type: Boolean,
      required: true,
    },

    limits: {
      type: Array,
      required: true,
    },

    methodsData: {
      type: Array,
      required: true,
    },

    initialSelectedMethod: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  @apply bg-background-positive-soft
      rounded-24
      p-s8
      flex
      justify-between
      items-center
      cursor-pointer
      relative
      z-20;
}

.dropdown-options-container {
  @apply
    overflow-hidden
    absolute
    w-full
    bg-background-page
    rounded-24
    z-10;
  top: 10px;
}

.dropdown-options {
  max-height: 180px;
}

.dropdown-option {
  @apply flex
      items-center
      justify-between
      p-s8
      border-t
      border-border
      bg-background-active
      bg-opacity-50
      cursor-pointer;
}

.method-icon-container {
  @apply bg-background-active-nav bg-opacity-10 text-text-active-2 rounded-full flex justify-center items-center p-s8;
}

.method-icon {
  @apply object-contain;
  width: 19px;
  height: 19px;
}

.empty-space {
  @apply bg-background-active bg-opacity-50;
  height: 48px;
}

::-webkit-scrollbar-track {
  @apply bg-background-active bg-opacity-50;
}
</style>
