<template>
  <div class="payment-interface">
    <Loading v-if="isLoading" version="v3" />

    <template v-else>
      <!-- Fixed at the top -->
      <div>
        <!-- Header -->
        <div class="headline-small">
          {{ $tc(`${typeStringLabel}.title_funds`) }}
        </div>

        <!-- Amount input -->
        <div class="flex justify-center mt-s40 mb-s12">
          <div
            class="input-amount"
            v-bind:style="{ opacity: amount > 0 ? 1 : 0.3 }"
          >
            $
          </div>
          <money
            data-testid="input-money"
            data-cy="input-money"
            class="w-full input-amount no-arrows"
            :placeholder="0"
            ref="amount"
            v-autowidth="{
              maxWidth: '392px',
              minWidth: 'none',
              comfortZone: 1,
            }"
            v-bind:style="{ opacity: amount > 0 ? 1 : 0.3 }"
            v-model="amount"
          />
        </div>

        <!-- Estimated time -->
        <template>
          <div
            v-if="amount > limit && isDeposit"
            class="
              border
              bg-background-negative-text
              body-text-large
              text-text-negative
              border-border-negative
              rounded-16
              px-s16
              py-s12
            "
          >
            {{
              $t('wallet.amount_over_daily', {
                limit: numberFormat(limit, 2, false, true),
                type: typeStringLabel,
              })
            }}
            <div
              @click="
                showModal('WalletTransferLimit', {
                  type: typeStringLabel,
                  isClosable: false,
                  noPadding: true,
                })
              "
              class="underline capitalize cursor-pointer"
            >
              {{ $t('wallet.view_limits', { type: typeStringLabel }) }}
            </div>
          </div>
          <div v-else class="estimated-time subheadline-x-small">
            <span
              >{{ $t('wallet.estimated_time') }}:
              {{
                $t(`wallet.estimated_time_options.${estimatedTimeOption}`)
              }}</span
            >
          </div>
        </template>

        <!-- Method dropdown -->
        <div class="text-center mt-s12">
          <SkeletonLoading
            v-if="!linkedMethods"
            height="58px"
            border-radius="20px"
          />
          <PaymentMethodSelectorDropdown
            v-else
            data-testid="select-payment-method"
            data-cy="select-payment-method"
            :methods-data="linkedMethods"
            :is-deposit="isDeposit"
            :limits="limits"
            :initial-selected-method="selectedMethodData"
            @addNewMethod="addNewMethod"
            @toggleDropdown="toggleDropdown"
            @removeMethod="removeMethod"
            @selectMethod="selectMethod"
          />
        </div>

        <!-- Receipt area -->
        <div class="subheadline-x-small mt-s32">
          <div class="flex justify-between">
            <span>{{ $t(`${typeStringLabel}.minimum`) }}</span>
            <span>{{ numberFormat(minimumAmount, 2, false, true) }}</span>
          </div>
          <div class="flex justify-between mt-s16">
            <span>{{ $t('wallet.your_balance') }}</span>
            <span>{{ numberFormat(userBalance, 2, false, true) }}</span>
          </div>
          <div class="flex justify-between mt-s16">
            <span>{{ $t(`${typeStringLabel}.fee`) }}</span>
            <span>{{ numberFormat(fee, 2, false, true) }}</span>
          </div>
          <div class="flex justify-between mt-s16">
            <span>{{ $t(`${typeStringLabel}.you_will_receive`) }}</span>
            <span>{{ numberFormat(userWillReceive, 2, false, true) }}</span>
          </div>
        </div>
      </div>

      <!-- Fixed at the bottom -->
      <div>
        <!-- Confirmation button -->
        <ButtonV2
          wide
          size="medium"
          data-testid="button-confirm-deposit"
          data-cy="button-confirm-deposit"
          :label="$tc(`${typeStringLabel}.confirm`)"
          :inactive="!amountIsValid || transactionLoading"
          @onClick="handleTransaction"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Money } from 'v-money';

import { ButtonV2, Loading } from '@/components/misc';
import { PaymentMethodSelectorDropdown } from '@/components/walletv2';
import CirclePaymentType from '@/enums/CirclePaymentType';
import MethodEventName from '@/enums/MethodEventName';

export default {
  name: 'PaymentInterface',
  components: {
    ButtonV2,
    Loading,
    PaymentMethodSelectorDropdown,
    Money
  },

  data() {
    return {
      amount: 0,
      isLoading: true,
      selectedMethodData: null,
      transactionLoading: false,
      methodStatusEnum: {
        enabled: 'enabled',
        requires_proof_of_banking: 'requires_proof_of_banking',
        pending: 'pending',
        denied: 'denied',
      },
    };
  },

  computed: {
    ...mapGetters('user', ['getUserPermissions']),

    ...mapState('wallet', ['linkedDepositMethods', 'linkedWithdrawalMethods']),
    ...mapGetters('wallet', [
      'getBalance',
      'getDepositLimits',
      'getDepositLimitsByMethodType',
      'getPaymentFee',
      'getPaymentMinimumAmount',
    ]),

    amountIsValid() {
      return this.amount >= this.minimumAmount && this.amount <= this.limit;
    },

    availableMethods() {
      const availableMethods = this.linkedMethods.map(
        (method) => MethodEventName[method.type],
      );

      return [...new Set(availableMethods)].join(', ');
    },

    estimatedTimeOption() {
      // todo integrate with the backend
      if (!this.isDeposit) {
        return '3_to_5';
      }

      switch (this.methodData.type) {
        case CirclePaymentType.WIRE:
          return '1_to_3';
        default:
          return 'instant';
      }
    },

    fee() {
      return this.getPaymentFee(this.typeStringLabel, this.methodData.type);
    },

    limit() {
      return this.isDeposit
        ? parseFloat(
            this.lodashGet(
              this.getDepositLimitsByMethodType(this.methodData.type),
              'remaining.daily',
              99999,
            ),
          )
        : 9999999;
    },

    limits() {
      return this.getDepositLimits.all;
    },

    methodData() {
      return this.selectedMethodData || this.linkedMethods[0];
    },

    minimumAmount() {
      return this.getPaymentMinimumAmount(
        this.typeStringLabel,
        this.methodData.type,
      );
    },

    linkedMethods() {
      const methods = this.isDeposit
        ? [...this.linkedDepositMethods]
        : [...this.linkedWithdrawalMethods];

      const blockchain = this.getDepositLimitsByMethodType(
        CirclePaymentType.BLOCKCHAIN,
      );

      if (blockchain.enabled) {
        methods.push({
          id: CirclePaymentType.BLOCKCHAIN,
          nickname: this.$t('wallet.blockchain'),
          type: CirclePaymentType.BLOCKCHAIN,
        });
      }

      return methods;
    },

    typeStringLabel() {
      return this.isDeposit ? 'deposit' : 'withdraw';
    },

    typeStringForEvents() {
      return this.isDeposit ? 'DEPOSIT' : 'WITHDRAWAL';
    },

    userBalance() {
      return this.getBalance.buyingPower;
    },

    userWillReceive() {
      try {
        return Math.max(this.$big(this.amount).sub(this.$big(this.fee)), 0);
      } catch (e) {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions('user', ['loadUserPermissions', 'updateProofOfBankId']),
    ...mapActions('wallet', [
      'loadPaymentLimits',
      'handleDepositFormSubmit',
      'handleWithdrawFormSubmit',
      'retrieveLinkedDepositMethods',
      'retrieveLinkedWithdrawalMethods',
    ]),
    ...mapActions('ui', ['hideToast']),

    addNewMethod() {
      this.showModal('WalletAddTransferMethod', { type: this.typeStringLabel });
    },

    onError(message) {
      this.showError(new Error(message));
    },

    onNeedProofOfBanking() {
      this.updateProofOfBankId(
        this.selectedMethodData.proof_of_banking_request.id,
      );
      return this.showModal('KycProofOfBank');
    },

    handleAchStatusCases() {
      switch (this.selectedMethodData.status) {
        case this.methodStatusEnum.denied:
          return this.onError('error_banking_rejected');
        case this.methodStatusEnum.requires_proof_of_banking:
          return this.onNeedProofOfBanking();
        default:
          return this.onError('error_in_analysis');
      }
    },

    async handleTransaction() {
      try {
        this.transactionLoading = true;

        if (
          this.selectedMethodData.type === 'ach' &&
          this.selectedMethodData.status !== this.methodStatusEnum.enabled
        ) {
          return this.handleAchStatusCases();
        }

        const transactionData = {
          amount: this.amount,
          fee: this.fee,
          methodData: this.methodData,
        };

        if (this.isDeposit) {
          await this.handleDepositFormSubmit(transactionData);
        } else {
          if (this.selectedMethodData.type === 'ach') {
            this.$store.dispatch('events/track', {
              event: 'WITHDRAW_SUBMITTED',
              variables: {
                amount: this.numberFormat(this.amount, 2, false, true),
                fee: this.numberFormat(this.fee, 2, false, true),
                payment_type: MethodEventName.ach,
              },
            });
          }
          await this.handleWithdrawFormSubmit(transactionData);
        }
      } finally {
        this.transactionLoading = false;
      }
    },

    removeMethod(method) {
      this.showModal('MethodRemovalConfirmation', {
        type: this.typeStringLabel,
        method,
      });
    },

    async retrieveLinkedMethods() {
      if (this.isDeposit) {
        await this.retrieveLinkedDepositMethods();
      } else {
        await this.retrieveLinkedWithdrawalMethods();
      }

      await this.loadPaymentLimits(); // withdraw fees are inside deposit_limits query :(
    },

    selectMethod(data) {
      this.selectedMethodData = data;
      this.$store.dispatch('events/track', {
        event: `${this.typeStringForEvents}_METHOD_CHANGE_VIEWED`,
      });
    },

    toggleDropdown(isOpen) {
      if (isOpen) {
        this.$store.dispatch('events/track', {
          event: `${this.typeStringForEvents}_PAYMENT_METHODS_SELECTION_VIEWED`,
          variables: {
            available_methods: this.availableMethods,
          },
        });
      }
    },
  },

  async mounted() {
    this.hideToast();
    await this.loadUserPermissions();

    if (
      (this.isDeposit && !this.getUserPermissions.deposit) ||
      (!this.isDeposit && !this.getUserPermissions.withdraw)
    ) {
      return this.showModal('KycStatus');
    }

    await this.retrieveLinkedMethods();

    const methodIndex = this.linkedMethods.findIndex(
      (method) => method.id === this.selectedMethodId,
    );

    this.selectedMethodData =
      this.linkedMethods[methodIndex !== -1 ? methodIndex : 0];

    if (
      this.lodashIsEmpty(this.linkedMethods) ||
      (this.linkedMethods[0].type === CirclePaymentType.BLOCKCHAIN &&
        !this.selectedMethodId)
    ) {
      return this.showModal('WalletAddTransferMethod', {
        type: this.typeStringLabel,
      });
    }

    this.$store.dispatch('events/track', {
      event: `${this.typeStringForEvents}_AMOUNT_VIEWED`,
      variables: {
        current_balance: this.isDeposit
          ? this.numberFormat(this.userBalance, 2, false, true)
          : null,
      },
    });

    this.isLoading = false;

    this.$nextTick(() => {
      this.$emit('modalLoaded');
    });
  },

  props: {
    isDeposit: {
      type: Boolean,
      default: false,
    },
    selectedMethodId: {
      type: String,
    },
  },

  watch: {
    amount(newValue) {
      if (newValue > this.limit && this.isDeposit) {
        this.$store.dispatch('events/track', {
          event: 'DEPOSIT_LIMIT_EXCEEDED',
          variables: {
            input_amount: this.numberFormat(newValue, 2, false, true),
            deposit_limit: this.numberFormat(this.limit, 2, false, true),
            deposit_method: MethodEventName[this.selectedMethodData.type],
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.payment-interface {
  @apply pt-s16 flex flex-col justify-between;
  height: 550px;
  max-height: 100%;
  width: 450px;
  max-width: 100%;
}

.input-amount {
  @apply text-text-body bg-transparent font-semibold;
  font-size: 72px;
  line-height: 80px;
}

.estimated-time {
  @apply text-text-positive text-center;
  padding: 23px 0;
}
</style>
